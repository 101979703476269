<template lang="html">
  <v-container fluid pa-0>
    <v-row dense>
      <v-col cols="12" v-if="!radiologyId">
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab key="awaiting">รอรายงานผล</v-tab>
            <v-tab key="reported">รายงานผลแล้ว</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="awaiting">
              <v-model-table model-name="radiologies" model-title="รายการตรวจรอรายงานผล"
                :model-api="['models','Radiology','Radiologies']"
                :model-api-search="awaitReportQuery"
                api-scope="unreported"
                :headers="headers"
                :insertable="false"
                server-pagination
              >
                <template v-slot:item.studyDateTime="props">
                  <v-label-datetime :dateTime="props.item.studyDateTime" tiny-date-time></v-label-datetime>
                </template>
                <template v-slot:item.action="props">
                  <v-btn :to="{name:'radiology.reporting', params: {radiologyId: props.item.id}}" color="primary"><v-icon class="mx-1">mdi-circle-edit-outline</v-icon> เปิด</v-btn>
                </template>
              </v-model-table>
            </v-tab-item>
            <v-tab-item key="reported">
              <v-model-table model-name="radiologies" model-title="รายการตรวจรายงานผลแล้ว"
                :model-api="['models','Radiology','Radiologies']"
                :model-api-search="reportedQuery"
                api-scope="reported"
                :headers="headers"
                :insertable="false"
                server-pagination
              >
                <template v-slot:item.studyDateTime="props">
                  <v-label-datetime :dateTime="props.item.studyDateTime" tiny-date-time></v-label-datetime>
                </template>
                <template v-slot:item.action="props">
                  <v-btn :to="{name:'radiology.reporting', params: {radiologyId: props.item.id}}" color="primary"><v-icon class="mx-1">mdi-circle-edit-outline</v-icon> เปิด</v-btn>
                </template>
              </v-model-table>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
      <v-col cols="12" v-if="radiologyId">
        <v-row dense>
          <v-col cols="12">
            <v-patient-bar :value="apiItemData.hn" @patientUnloaded="closeStudy"></v-patient-bar>
          </v-col>
          <v-col cols="12">
            <v-tabs-group>
              <template v-slot:tabs>
                <v-tab key="currentStudy">รายการตรวจครั้งนี้</v-tab>
                <v-tab key="allStudies">การตรวจทั้งหมด</v-tab>
              </template>
              <template v-slot:items>
              <v-tab-item key="currentStudy">
                  <v-card>
                    <v-card-text>
                      <v-container fluid>
                        <v-row>
                          <v-col cols="8">
                            <span class="headline font-weight-bold">{{ apiItemData.modality }} {{ apiItemData.description }}</span><br>
                            <span class="headline font-weight-regular"><v-label-datetime :date-time="apiItemData.studyDateTime" tinyDateTime></v-label-datetime></span>
                          </v-col>
                          <v-col cols="4" class="text-right">
                            <v-btn color="primary" @click="openPacs(apiItemData.uid)">เปิด PACS</v-btn>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" class="pa-0">
                            <v-document-editor v-model="apiItemData.reportDocumentId" 
                              :hn="apiItemData.hn" folder="radiology"
                              @documentSaved="onReportSaved" 
                              templateCode="MRC-FM-085"
                              :initialData="initialReportData"
                              approveOnSave
                              flat
                            ></v-document-editor>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-radiology-list :hn="apiItemData.hn"></v-radiology-list>
                </v-tab-item>
              </template>
            </v-tabs-group>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiItem from '@/components/mixins/apiItem'
import pacs from '@/components/mixins/pacs'
import { isUndefined,isNull } from 'lodash'

export default {
  mixins: [apiItem,pacs],
  data: ()=>({
    apiBaseUrl: ['models','Radiology','Radiologies'],
    headers: [
      {
        text: 'วันที่ทำการตรวจ',
        value: 'studyDateTime',
        class: 'body-2'
      },
      {
        text: 'Accession No',
        value: 'accessionNumber',
        class: 'body-2'
      },
      {
        text: 'ชนิด',
        value: 'modality',
        class: 'body-2'
      },
      {
        text: 'ส่วนที่ตรวจ',
        value: 'description',
        class: 'body-2'
      },
      {
        text: 'แพทย์ผู้ส่งตรวจ',
        value: 'referringDoctor',
        class: 'body-2'
      },
      {
        text: 'รังสีแพทย์',
        value: 'reportingDoctorCode',
        class: 'body-2'
      },
      {
        text: 'จำนวนภาพ',
        value: 'imageCount',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ]
  }),
  props: {
    radiologyId: {
      type: [String , Number],
      default: undefined
    }
  },
  watch: {
    radiologyId: function(newVal) {
      this.loadStudy(newVal)
    }
  },
  methods: {
    onReportSaved() {
      if (this.isItemDataChange) {
        let saveData = {
          id: this.apiItemData.id,
          reportDocumentId: this.apiItemData.reportDocumentId
        }
        this.updateItem(saveData)
      }
    },
    closeStudy() {
      this.$router.push({name: 'radiology.reporting'})
    },
    loadStudy(id) {
      if (!isUndefined(id) && !isNull(id)) {
        this.findItem(id).catch((e)=>{
          void e
          this.$router.push({name: 'radiology.reporting'})
        })
      }
    }
  },
  computed: {
    awaitReportQuery() {
      return {
        reportingDoctorCode: this.$store.getters.getCurrentUser.doctorCode,
      }
    },
    reportedQuery() {
      return {
        reportingDoctorCode: this.$store.getters.getCurrentUser.doctorCode,
      }
    },
    initialReportData() {
      if (this.apiItemData) {
        return {
          study: (this.apiItemData.description) ? this.apiItemData.modality+' '+this.apiItemData.description : null,
          studyDateTime: this.apiItemData.studyDateTime,
          requestDoctor: this.apiItemData.requestDoctor,
          reportingDoctorCode: this.$store.getters.getCurrentUser.doctorCode
        }
      } else {
        return undefined
      }
    }
  },
  created() {
    this.loadStudy(this.radiologyId)
  }
}
</script>

<style lang="css" scoped>
</style>
